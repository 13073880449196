<template>
    <div>
        <aw-tabler :table="table">
            <template #filter>
                <el-form ref="formRef" :model="table.filter" :inline="true">
                    <el-form-item label="用户名/姓名:">
                        <el-input v-model="table.filter.username" placeholder="请输入" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="所属权限:">
                        <el-select v-model="table.filter.group_id" placeholder="请选择">
                            <el-option v-for="(item, index) in group_options" :key="index" :label="item.role_name" :value="item.id" />
                        </el-select>
                    </el-form-item>
                </el-form>
            </template>
            <template #drawercontent>
                <MemberAddEdit ref="memberAddEditRef" :modelInfo="{ flex: table.drawer.flex, form: table.drawer.form }" @close-drawer="table.drawer.close" />
            </template>
        </aw-tabler>
    </div>
</template>
<script setup lang="jsx">
import { http, util, dict, tool } from '@/assets/js'
import { reactive, ref } from 'vue'
import { tableColumn } from '@/assets/js/extend/tableColumn'
import MemberAddEdit from './MemberAddEdit.vue'
import { useRoute } from 'vue-router'

const route = useRoute()
// 当前页面操作权限集
const powerlimits = reactive(tool.getArrayToOneChat(route.meta.role, 'component'))

const group_options = ref('')
const selectChangeEvent = async (data) => {
    group_options.value = await http.get('/admin/roles')
}
selectChangeEvent()

const memberAddEditRef = ref(null)
const table = reactive({
    title: '管理员',
    select: false,
    loading: false,
    filter: {
        username: '',
        group_id: ''
    },
    datas: [],
    columns: [
        ...tableColumn([
            { title: 'ID', key: 'id', minWidth: '100px' },
            { title: '用户名', key: 'username', minWidth: '100px' },
            { title: '姓名', key: 'truename', minWidth: '100px' },
            { title: '手机号', key: 'mobile', minWidth: '100px' },
            {
                title: '所属权限',
                key: 'group_id_name',
                minWidth: '100px',
                render: (data) => {
                    if (group_options.value != '') {
                        let options = util.deepcopy(group_options.value)
                        return options.find((n) => n.id == data.group_id)?.['role_name']
                    }
                }
            },
            { title: '添加时间', key: 'addtime', minWidth: '180px', time: true },
            { title: '最近一次登录时间', key: 'last_login_time', minWidth: '180px', time: true },
            { title: '最近一次登录IP', key: 'last_login_ip', minWidth: '180px' },
            {
                title: '是否启用',
                key: 'status',
                minWidth: '100px',
                props: (data) => ({ disabled: !powerlimits.includes('gly_qy') }),
                confirm: true,
                switch: ({ id }) => http.post('/admin/adminUser/switch', { id })
            }
        ]),
        {
            fixed: 'right',
            title: '操作',
            key: 'operate',
            minWidth: '160px',
            buttons: [
                {
                    name: '编辑',
                    props: { type: 'primary', size: 'small' },
                    show: () => powerlimits.includes('gly_bj'),
                    action: async (data) => {
                        table.drawer.show = true
                        table.drawer.title = '编辑'

                        let result = await http.post('/admin/adminUser/view', { id: data.id })
                        table.drawer.flex = 'edit'
                        table.drawer.form = {
                            id: result.id,
                            username: result.username,
                            truename: result.truename,
                            group_id: result.group_id,
                            group_id_data: group_options.value,
                            pass: '',
                            checkPass: '',
                            remark: result.remark,
                            mobile: result.mobile
                        }
                    }
                }
            ]
        }
    ],
    page: {
        total: 0,
        page: 1,
        page_size: 20,
        sizeChange: (val) => {
            table.page.page_size = val
            table.action(table.filter)
        },
        currentChange: (val) => {
            table.page.page = val
            table.action(table.filter)
        }
    },
    action: async (params) => {
        if (powerlimits.includes('gly_lb')) {
            table.loading = true
            let page = { page: table.page.page, page_size: table.page.page_size }
            let result = await http.post('/admin/adminUser/index', { ...params, ...route.query, ...page })
            table.datas = result.data
            table.page.total = result.total || Infinity
            table.page.page = result.current_page
            table.page.page_size = result.per_page
            table.loading = false
        } else {
            table.datas = []
            table.page.total = Infinity
        }
        return
    },
    extras: [
        {
            name: '添加',
            props: { type: 'primary', size: 'small' },
            show: () => powerlimits.includes('gly_tj'),
            action: () => {
                table.drawer.show = true
                table.drawer.title = '添加'

                table.drawer.flex = 'add'
                table.drawer.form = {
                    username: '',
                    truename: '',
                    group_id: '',
                    group_id_data: group_options.value,
                    pass: '',
                    checkPass: '',
                    remark: '',
                    mobile: ''
                }
            }
        }
    ],
    drawer: reactive({
        flex: null,
        form: {},
        show: false,
        title: '',
        close: () => {
            table.drawer.show = false
            memberAddEditRef.value.resetFieldsEvent()
            table.page.currentChange(1)
        }
    })
})
</script>
<style scoped></style>
