<template>
    <div>
        <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
            <el-form-item label="登录用户名：" prop="username">
                <el-input v-model="ruleForm.username" placeholder="请输入" clearable maxlength="50" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="姓名：" prop="truename">
                <el-input v-model="ruleForm.truename" placeholder="请输入" clearable maxlength="50" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="所属权限组：" prop="group_id">
                <el-select v-model="ruleForm.group_id" placeholder="请选择" style="width: 100%">
                    <el-option :label="item.role_name" :value="item.id" v-for="(item, index) in ruleForm.group_id_data" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="登录密码：" prop="pass">
                <el-input v-model="ruleForm.pass" type="password" autocomplete="off" show-password clearable></el-input>
            </el-form-item>
            <el-form-item label="确认密码：" prop="checkPass">
                <el-input v-model="ruleForm.checkPass" type="password" autocomplete="off" clearable></el-input>
            </el-form-item>
            <el-form-item label="备注信息：" prop="remark">
                <el-input v-model="ruleForm.remark" type="textarea" placeholder="请输入" clearable maxlength="200" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="手机号：" prop="mobile">
                <el-input v-model="ruleForm.mobile" placeholder="请输入" clearable maxlength="11" show-word-limit></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="saveEvent('ruleFormRef')" :disabled="disabled_btn">保存</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import { ref } from 'vue'
import { http } from '@/assets/js'

export default {
    name: 'MemberAddEdit',
    inject: ['reload'],
    props: { modelInfo: { type: Object, required: true } },
    watch: {
        modelInfo: {
            handler(newVal) {
                this.model_info = newVal
                this.ruleForm = this.model_info.form
            },
            immediate: true,
            deep: true
        }
    },
    data() {
        return {
            model_info: {},
            ruleForm: {
                username: '',
                truename: '',
                group_id: '',
                group_id_data: [],
                pass: '',
                checkPass: '',
                remark: '',
                mobile: ''
            }, //form表单
            rules: {
                username: [
                    {
                        required: true,
                        message: '登录用户名为必填项',
                        trigger: 'blur'
                    }
                ],
                truename: [
                    {
                        required: true,
                        message: '姓名为必填项',
                        trigger: 'blur'
                    }
                ],
                group_id: [
                    {
                        required: true,
                        message: '所属权限组为必选项',
                        trigger: 'blur'
                    }
                ],
                pass: [
                    {
                        required: true,
                        validator: this.validatePass,
                        trigger: 'blur'
                    }
                ],
                checkPass: [
                    {
                        required: true,
                        validator: this.validatePass2,
                        trigger: 'blur'
                    }
                ],
                remark: [
                    {
                        required: false,
                        message: '备注信息为必填项',
                        trigger: 'blur'
                    }
                ],
                mobile: [
                    {
                        required: true,
                        message: '手机号为必填项',
                        trigger: 'blur'
                    }
                ]
            },
            disabled_btn: false
        }
    },
    methods: {
        //提交第一个密码
        validatePass(rule, value, callback) {
            if (value === '') {
                callback(new Error('请输入登录密码'))
            } else {
                if (this.ruleForm.checkPass !== '') {
                    this.$refs['ruleFormRef'].validateField('checkPass')
                }
                callback()
            }
        },
        //校验-第二个密码
        validatePass2(rule, value, callback) {
            if (value === '') {
                callback(new Error('请输入确认密码'))
            } else if (value !== this.ruleForm.pass) {
                callback(new Error('2次输入的密码不同！请重新输入'))
            } else {
                callback()
            }
        },

        saveEvent(formName) {
            let getdatas = this.ruleForm
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.disabled_btn = true
                    let result = null
                    if (this.model_info.flex == 'add') {
                        result = await http.post(
                            '/admin/adminUser/add',
                            {
                                username: getdatas.username,
                                truename: getdatas.truename,
                                password: getdatas.pass,
                                confirm_password: getdatas.checkPass,
                                group_id: getdatas.group_id,
                                remark: getdatas.remark,
                                mobile: getdatas.mobile
                            },
                            { sMsg: true }
                        )
                    } else {
                        result = await http.post(
                            '/admin/adminUser/edit',
                            {
                                id: getdatas.id,
                                username: getdatas.username,
                                truename: getdatas.truename,
                                password: getdatas.pass,
                                confirm_password: getdatas.checkPass,
                                group_id: getdatas.group_id,
                                remark: getdatas.remark,
                                mobile: getdatas.mobile
                            },
                            { sMsg: true }
                        )
                    }
                    if (result.code == 100) {
                        this.disabled_btn = false
                        return
                    }
                    this.$emit('close-drawer', true)
                    this.$refs[formName].resetFields()
                    this.disabled_btn = false
                } else {
                    return false
                }
            })
        },
        resetFieldsEvent() {
            this.$refs['ruleFormRef'].resetFields()
        }
    }
}
</script>
<style lang="scss" scoped></style>
